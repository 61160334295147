import { useState } from "react";
import { useAppContext } from "../../../../context/AppContext";
import { isMobile } from "react-device-detect";
import BPlatinum from "../platinum/b";
import CPlatinum from "../platinum/c";
import BGold from "../gold/b";
import CGold from "../gold/c";
import BSilver from "../silver/b";
import CSilver from "../silver/c";
// import Balcony3 from "../balcony/3";
// import Balcony4 from "../balcony/4";
// import Balcony5 from "../balcony/5";
// import Balcony7 from "../balcony/7";
// import Balcony8 from "../balcony/8";
// import Balcony9 from "../balcony/9";
import BVIP from "../vip/b";
import CVIP from "../vip/c";

import styles from "./index.module.css";

const Pops7thSeatMap = ({ eventCard }) => {
  const { setModal } = useAppContext();
  const [maxWidth, setMaxWidth] = useState(isMobile ? 400 : 700);
  const zoomOut = () => {
    if (maxWidth < 1000) {
      setMaxWidth(maxWidth + 30);
    }
  };
  const zoomIn = () => {
    if (maxWidth > 400) {
      setMaxWidth(maxWidth - 30);
    }
  };

  return (
    <div className="container">
      <div className={styles.zoom_out}>
        <div onClick={() => zoomOut()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_out.svg"
            alt="zoom_out"
          />
        </div>
      </div>
      <div className={styles.zoom_in}>
        <div onClick={() => zoomIn()} className={styles.sit_contain}>
          <img
            className={styles.pieceMap}
            src="/img/map/zoom_in.svg"
            alt="zoom_in"
          />
        </div>
      </div>
      <p className="seating__area_txt" style={{ width: maxWidth }}>
        1. Please select the seating area first
      </p>
      <div className="pops__map_container" style={{ width: maxWidth }}>
        <div className={styles.stage}>
          <div className={styles.sit_contain}>
            <img
              className={styles.stage_img}
              src="/img/map/Pops/stage.svg"
              alt="stage"
            />
          </div>
        </div>
        <div className={styles.b_vip}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "7b2d82c3-5aab-44d8-8a11-8190cf0e0c48" &&
                setModal({
                  open: true,
                  children: <BVIP eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "7b2d82c3-5aab-44d8-8a11-8190cf0e0c48"
                    ? "/img/map/Pops/b_vip_w.svg"
                    : "/img/map/Pops/b_vip.svg"
                }
                alt="B vip"
              />
            </div>
          </div>
        </div>
        <div className={styles.b_platinum}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "9805c55d-f00b-4d47-8210-c546b15c29a6" &&
                setModal({
                  open: true,
                  children: <BPlatinum eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "9805c55d-f00b-4d47-8210-c546b15c29a6"
                    ? "/img/map/Pops/platinum_w.svg"
                    : "/img/map/Pops/platinum.svg"
                }
                alt="B platinum"
              />
            </div>
          </div>
        </div>
        <div className={styles.b_gold}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "59e4b8e7-6b25-49f5-9732-2402260c65ae" &&
                setModal({
                  open: true,
                  children: <BGold eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "59e4b8e7-6b25-49f5-9732-2402260c65ae"
                    ? "/img/map/Pops/gold_w.svg"
                    : "/img/map/Pops/gold.svg"
                }
                alt="B gold"
              />
            </div>
          </div>
        </div>
        <div className={styles.b_silver}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "0b5066b5-af11-4835-a797-2fc183c44851" &&
                setModal({
                  open: true,
                  children: <BSilver eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "0b5066b5-af11-4835-a797-2fc183c44851"
                    ? "/img/map/Pops/silver_w.svg"
                    : "/img/map/Pops/silver.svg"
                }
                alt="B silver"
              />
            </div>
          </div>
        </div>
        <div className={styles.c_vip}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "7b2d82c3-5aab-44d8-8a11-8190cf0e0c48" &&
                setModal({
                  open: true,
                  children: <CVIP eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "7b2d82c3-5aab-44d8-8a11-8190cf0e0c48"
                    ? "/img/map/Pops/c_vip_w.svg"
                    : "/img/map/Pops/c_vip.svg"
                }
                alt="C vip"
              />
            </div>
          </div>
        </div>
        <div className={styles.c_platinum}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "9805c55d-f00b-4d47-8210-c546b15c29a6" &&
                setModal({
                  open: true,
                  children: <CPlatinum eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "9805c55d-f00b-4d47-8210-c546b15c29a6"
                    ? "/img/map/Pops/platinum_w.svg"
                    : "/img/map/Pops/platinum.svg"
                }
                alt="C platinum"
              />
            </div>
          </div>
        </div>
        <div className={styles.c_gold}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "59e4b8e7-6b25-49f5-9732-2402260c65ae" &&
                setModal({
                  open: true,
                  children: <CGold eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "59e4b8e7-6b25-49f5-9732-2402260c65ae"
                    ? "/img/map/Pops/gold_w.svg"
                    : "/img/map/Pops/gold.svg"
                }
                alt="C gold"
              />
            </div>
          </div>
        </div>
        <div className={styles.c_silver}>
          <div className={styles.sit_contain}>
            <div
              onClick={() =>
                eventCard.id === "0b5066b5-af11-4835-a797-2fc183c44851" &&
                setModal({
                  open: true,
                  children: <CSilver eventCard={eventCard} />,
                })
              }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src={
                  eventCard.id === "0b5066b5-af11-4835-a797-2fc183c44851"
                    ? "/img/map/Pops/silver_w.svg"
                    : "/img/map/Pops/silver.svg"
                }
                alt="C silver"
              />
            </div>
          </div>
        </div>
        <div className={styles.balcony_3}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <Balcony3 eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Pops/balcony_3.svg"
                alt="Balcony 3"
              />
            </div>
          </div>
        </div>
        <div className={styles.balcony_4}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <Balcony4 eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Pops/balcony_3.svg"
                alt="Balcony 4"
              />
            </div>
          </div>
        </div>
        <div className={styles.balcony_5}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <Balcony5 eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Pops/balcony_5.svg"
                alt="Balcony 5"
              />
            </div>
          </div>
        </div>
        <div className={styles.balcony_7}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <Balcony7 eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Pops/balcony_7.svg"
                alt="Balcony 7"
              />
            </div>
          </div>
        </div>
        <div className={styles.balcony_8}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <Balcony8 eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Pops/balcony_7.svg"
                alt="Balcony 8"
              />
            </div>
          </div>
        </div>
        <div className={styles.balcony_9}>
          <div className={styles.sit_contain}>
            <div
              // onClick={() =>
              //   setModal({
              //     open: true,
              //     children: <Balcony9 eventCard={eventCard} />,
              //   })
              // }
              className={styles.sit_contain}
            >
              <img
                className={styles.pieceMap}
                src="/img/map/Pops/balcony_7.svg"
                alt="Balcony 9"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pops7thSeatMap;
